import React from "react";
import { graphql } from "gatsby";

import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import SiteMetadata from "../components/SiteMetadata";
import Layout from "../layouts/Layout";
import ArticleList from "../components/ArticleList";

import { GatsbyImage } from "gatsby-plugin-image";

export default function BlogPost(props) {
  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <div className="font-bold">{text}</div>,
    },
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <h1 className="text-2xl font-semibold my-4">{children}</h1>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <h1 className="text-2xl font-semibold my-4">{children}</h1>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h1 className="text-xl font-semibold my-4">{children}</h1>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <h1 className="text-lg font-semibold my-4">{children}</h1>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
      [BLOCKS.UL_LIST]: (node, children) => children,
      [INLINES.HYPERLINK]: (node, children) => (
        <a
          className="font-semibold text-red-700"
          href={node.data.uri}
          target="__blank"
        >
          {children}
        </a>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        return (
          <div className="p-12 flex justify-center">
            <GatsbyImage image={node.data?.target?.gatsbyImageData} />
          </div>
        );
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        return <div>{JSON.stringify(node.data.target)}</div>;
      },
    },
  };

  return (
    <Layout>
      <SiteMetadata
        title={`${props.data.item.title} 🤩`}
        description={props.data.item.seoDescription}
        image={`${props.data.item.image.file.url}`}
      />
      <div className="w-full mx-auto 2xl:max-w-screen-xl 2xl:px-8">
        <div
          className="flex flex-wrap justify-center items-center mb-8 2xl:rounded-lg "
          style={{
            backgroundImage: `url(${props.data.item.image.file.url})`,
            backgroundPosition: "bottom",
            backgroundSize: "cover",
          }}
        >
          <h2 className="py-40 font-display text-4xl sm:text-6xl leading-tight tracking-tight text-white text-center">
            {props.data.item.title}
            <span className="text-yellow-500">.</span>
          </h2>
        </div>
      </div>

      <div className="container pb-8">
        <div className="max-w-screen-md mx-auto text-lg">
          {renderRichText(props.data.item.body, options)}
        </div>
      </div>

      <ArticleList />
    </Layout>
  );
}

export const query = graphql`
  query BlogPostQuery($title: String!) {
    item: contentfulBlogpost(title: { eq: $title }) {
      title
      body {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData(height: 400, formats: [JPG])
          }
        }
      }
      seoDescription {
        seoDescription
      }
      image {
        file {
          url
        }
      }
    }
  }
`;
